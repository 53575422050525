import axios from 'axios';

export default class ProcedureStepService {

	getProcedimientoPasos() {			
        const url = process.env.VUE_APP_APIURL + `procedimientopasos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getProcedimientoPaso(procedimientopaso) {
        const url = process.env.VUE_APP_APIURL + `/procedimientopasos/${procedimientopaso.id_procedimiento_paso}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateProcedimientoPaso(procedimientopaso){
        const url = process.env.VUE_APP_APIURL + `/procedimientopasos/${procedimientopaso.id_procedimiento_paso}`;
		return axios.put(url,procedimientopaso).then(res=>res.data.data);
	}

	createProcedimientoPaso(procedimientopaso){
        const url = process.env.VUE_APP_APIURL + `/procedimientopasos`;
        console.log(procedimientopaso);
		return axios.post(url,procedimientopaso).then(res=>res.data.data);
	}

	deleteProcedimientoPaso(procedimientopaso){
        const url = process.env.VUE_APP_APIURL + `/procedimientopasos/${procedimientopaso.id_procedimiento_paso}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}