

import { DxDataGrid, DxColumn, DxEditing } from 'devextreme-vue/data-grid';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import ProcedureStepService from '../services/procedure-step-service';

export default {
  components: { DxDataGrid, DxColumn, DxEditing },
  props: {
    templateData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    
    const { nombre } = this.templateData.data;
    console.log("DATOS");
    console.log(this.templateData.data);
    console.log(this.templateData.key);
    console.log(`${nombre}`);
    return {
      steps: null,
      dataSourceProcedureSteps: null,
      detailInfo: `${nombre}`,
    };
  },
  created() {
    this.procedureStepService = new ProcedureStepService();      
  },
  mounted() {    

    this.procedureStepService.getProcedimientoPasos().then((data) => {
        this.steps=data;         
        this.dataSourceProcedureSteps = this.getSteps(this.templateData.key); 
    });
             
  },
  methods: {
    editProcedureStep(e) {
        this.procedureStepService.updateProcedimientoPaso(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.refreshData();
      });
    },
    deleteProcedureStep(e) {
        this.procedureStepService.deleteProcedimientoPaso(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.refreshData();
      });
    },
    createProcedureStep(e) {
        e.data.id_procedimiento = this.templateData.key;
        this.procedureStepService.createProcedimientoPaso(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.refreshData();
      });
    },
    refreshData() {
        this.procedureStepService.getProcedimientoPasos().then((data) => {
        this.steps=data;         
        this.dataSourceProcedureSteps = this.getSteps(this.templateData.key); 
    });
    },           
    getSteps(key) {
      console.log(key);
      return new DataSource({
        store: new ArrayStore({
          data: this.steps,
          key: 'id_procedimiento_paso',
        }),
        filter: ['id_procedimiento', '=', key],
      });
    },
  },
};
